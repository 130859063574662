// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-transparent.png'; // Import your transparent logo image
import './Header.css'; // Ensure the CSS file is imported

function Header() {
  return (
    <header>
      <div className="header-wrapper">
        {/* Logo Section */}
        <div className="logo-section">
          <Link to="/" className="logo-button">
            <img src={logo} alt="Home Logo" className="logo-image" />
          </Link>
        </div>

        {/* Navigation Links */}
        <nav>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/portfolio">Portfolio</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
