// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../assets/logo-transparent -white.png'; // Path to your logo
import fbIcon from '../assets/facebookico.png'; // Path to Facebook icon
import instaIcon from '../assets/instaico.jpg'; // Path to Instagram icon
import linkedinIcon from '../assets/linkedinico.jpg'; // Path to LinkedIn icon

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        {/* Logo Button on the Left */}
        <Link to="/" className="footer-logo-button">
          <img src={logo} alt="Logo" className="footer-logo-image" />
        </Link>

        {/* Footer Text in the Center */}
        <p>&copy; 2024 Galani Structures. All Rights Reserved.</p>

        {/* Social Media Icons on the Right */}
        <div className="social-media-icons">
          <a href="https://www.facebook.com/galanistructures" target="_blank" rel="noopener noreferrer">
            <img src={fbIcon} alt="Facebook" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/galanistructures" target="_blank" rel="noopener noreferrer">
            <img src={instaIcon} alt="Instagram" className="social-icon" />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
